<template>
  <div>
    <sidebar />
    <div class="main">
      <upbar title="Licenses" class="pb-5" /> <br />
      <br />
      <div class="plus" v-if="showAddLicenses" @click="addLicenses">
        <div class="plusIn">
          <span> Add licenses</span>
        </div>
      </div>
      <div class="bar row">
        <div class="col-3">
          <p class="tabTitle">Type</p>
        </div>
        <div class="col-3">
          <p class="tabTitle">Total</p>
        </div>
        <div class="col-3">
          <p class="tabTitle">Burned</p>
        </div>
        <div class="col-3">
          <p class="tabTitle">Available</p>
        </div>
      </div>
      <div v-for="license in licenses" :key="license.type" class="bar1 row">
        <div class="col-3">
          <p class="tabText">{{ getType(license.type) }}</p>
        </div>
        <div class="col-3">
          <p class="tabText">{{ license.total }}</p>
        </div>
        <div class="col-3">
          <p class="tabText">{{ license.burned }}</p>
        </div>
        <div class="col-3">
          <p class="tabText">{{ license.available }}</p>
        </div>
      </div>
       <div class="shoppingcart"  @click="goToBuyPage">
          <b-icon icon="cart4" font-scale="1.5"></b-icon>
          <span> Buy licenses</span>
      </div>
    </div>
    <b-modal  title="Add Licenses" id="add-license" hide-footer>
        <addLicense />
      </b-modal>
  </div>

</template>

<script>
import api from "../api/index";
import sidebar from "../components/sidebar.vue";
import upbar from "../components/upbar.vue";
import addLicense from "../components/popup/addLicense.vue";

export default {
  name: "License",
  components: {
    sidebar,
    upbar,
    addLicense
  },
  data() {
    return {
      logged: false,
      licenses: [],
    };
  },
  mounted() {
    this.logged = this.$store.getters.isAuthenticated;
    this.loadLicenses();
  },
  computed:{
     showAddLicenses(){
      if(this.$store.getters.getCustomerName === 'Rafa Ayadi'){
        return true;
      }
      else{
        return false;
      }
    },
  },
  methods: {
    goToBuyPage(){
        this.$router.push({ path: "/Buy" });
    },
    addLicenses(){
      this.$bvModal.show("add-license");
    },
    makeToast(textToShow) {
      this.$bvToast.toast(textToShow, {
        title: "Oops! Something went wrong.",
        variant: "warning",
        toaster: "b-toaster-top-center",
        solid: false,
      });
    },
    getType(licenseType) {
      if (licenseType === 1) {
        return "Trial Planner";
      } else if(licenseType === 2) {
        return "Standard Planner";
      }else if(licenseType === 3) {
        return ("Standard Groups/Teams");
      } else if(licenseType === 4) {
        return ("Trial Groups/Teams");
      } else if(licenseType === 5) {
        return ("Planner Backup");
      } else if(licenseType === 6) {
        return ("Teams User Chats");
      }else if(licenseType === 7) {
        return ("Trial Teams User Chats");
      }
    },
    loadLicenses() {
      api.getLicenseSummary().then((response) => {
        this.licenses = response.responseData;
      });
      if (this.licenses.Count === 0) {
        const emptyLicense = {
          licenseType: 0,
          total: 0,
          Available: 0,
          Burned: 0,
        };
        this.licenses.push(emptyLicense);
      }
    },
  },
};
</script>
<style scoped>
.main {
  margin-top: 53px;
  margin-left: 370px;
}
.bar {
  margin-top: 53px;
  margin-right: 40px;
  height: 53px;
  border-radius: 2px;
  background: #f2f6ff;
}
.bar1 {
  margin-right: 40px;
  height: 82px;
  border-radius: 2px;
  border-bottom: 2px solid #dfe0eb;
}
.tabTitle {
  margin-top: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;
  color: #2a4192;
}
.bicon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  cursor: pointer;
}
.tabText {
  margin-top: 28px;
  font-size: 20px;
  line-height: 21px;
  color: #252733;
}
.buttons {
  margin-top: 18px;
}
.botton {
  width: 155px;
  height: 44px;
  margin-right: 25px;
}
.icon {
  margin-right: 25px;
}
.altosio-icon {
  width: 21px;
  height: 36px;
}
.plus {
  margin-right: 40px;
  float: right;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #2a4192;
  border-radius: 5px;
}
.shoppingcart {
  margin-inline: auto;
  margin-top: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #2a4192;
  border-radius: 5px;
}
.plusIn {
  margin-top: 10px;
  margin-left: 40px;
}
.iconPlus {
  margin-right: 20px;
}
.shape {
  margin-top: 15px;
  margin-left: 32px;
  height: 50px;
}
.blue {
  background: #2a4192;
  border-radius: 5px;
}
</style>
