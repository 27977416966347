<template>
  <div>
    <div class="title">Add License</div>
    <div class="form">
      <p class="subtitle mt-3">Number of licenses to add</p>
      <b-form-input
        v-model="form.count"
        type="text"
        class="shape col-9"
      ></b-form-input>
    </div>
     <div class="form">
      <p class="subtitle mt-3">License Type</p>
      <b-form-select class="shape col-9" v-model="selectedOption" :options="options"></b-form-select>
    </div>
     <div class="form">
      <p class="subtitle mt-3">CustomerId</p>
      <b-form-input
        v-model="customerId"
        type="text"
        class="shape col-9"
      ></b-form-input>
    </div>
<br>
    <div class="button mb-5">
      <b-button variant="primary" v-on:click="addlicenses()"
        >Add licenses</b-button
      >
    </div>
  </div>
</template>

<script>
import api from "@/api/index";
export default {
  data() {
    return {
      form: {
        count: 1,
        type:2
      },
      selectedOption: null,
      options: [
          { value: null, text: 'Please select an option' },
          { value: '1', text: 'Trial Planner' },
          { value: '2', text: 'Standard Planner' },
          { value: '3', text: 'Standard Teams' },
          { value: '4', text: 'Trial Teams' },
          { value: '5', text: 'Planner Backup' },
          { value: '6', text: 'User Private Chats' }
        ],
      customerId: ''
    };
  },
  methods: { 
    addlicenses() {
      this.form.count = parseInt(this.form.count);
      this.form.type = parseInt(this.selectedOption);
      api.addLicenses(this.form, this.customerId)
        .then(() => {
           alert('Add licenses done.');
           this.$bvModal.hide("add-license");
        })
        .catch((error) => {
          const message = error.responseData;
          alert('Add licenses Error.' + message);
          this.$bvModal.hide("add-license");
        });
    },
  },
};
</script>

<style scoped>
.all {
  text-align: center;
}
.form {
  margin-left: 28%;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: #23438e;
}
.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23438e;
}
.shape {
  width: 480px;
  height: 56px;
}
.botton {
  margin-top: 30px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.button {
  text-align: center;
}
</style>
